<template>
    <div class="FishDatalist">
        <Fishlist2 class="Fishlist2"></Fishlist2>
    </div>
  
  </template>
  
  <script>
  import Fishlist2 from '@/components/Fishlist2.vue';
  export default {
    name: 'FishDatalist',
    components: {Fishlist2},
    data: () => ({
      //
    }),
  }
  </script>
  
  <style>
  </style>
  