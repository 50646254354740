<template>
    <div class="SignupSection">
        <signupsection ></signupsection>
    </div>
  
  </template>
  
  <script>
  
  
  // Components
  import signupsection from '@/components/SignupSection.vue';
  export default {
    name: 'SignupSection',
    components: { signupsection},
    data: () => ({
      //
    }),
  }
  </script>
  
  <style scoped>
  </style>