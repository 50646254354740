<template>
    <div class="UserData">
      <EditUser ></EditUser>
    </div>
  
  </template>
  
  <script>
  import EditUser from '@/components/EditUser.vue';
  
  // Components
  
  export default {
    name: 'UserData',
    components: { EditUser},
    data: () => ({
      //
    }),
  }
  </script>
  
  <style>
  
  </style>
  