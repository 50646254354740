<template>
    <div class="Controllerview">
      <controllerlist></controllerlist>
    </div>
  
  </template>
  
  <script>
  import controllerlist from '@/components/controller.vue';
  
  export default {
    name: 'Controllerview',
    components: { controllerlist},
    data: () => ({
      //
    }),
  }
  </script>
  
  <style>
  
  
  </style>
  